<template>
  <v-col
    cols="12"
    class="welcome__container align-self-center d-flex flex-column flex-grow-1 align-center justify-center pa-0"
  >
    <v-col cols="7" sm="4" md="3" lg="2" xl="3">
      <v-progress-circular
        v-if="!hasImage"
        class="center-component"
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
      <v-img
        v-show="hasImage"
        :src="leo"
        eager
        contain
        max-height="500"
        @load="setHasImage"
      />
    </v-col>
    <v-btn
      v-if="hasImage"
      large
      rounded
      color="#ead148"
      @click="chooseForm"
      id="start"
      class="start-btn ma-8"
      :loading="isLoading || loadingAudios"
      :disabled="loadingAudios"
    >
      <p class="ma-0 text-h5 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h3 pt-1">
        {{ $t('start') }}
      </p>
    </v-btn>
  </v-col>
</template>

<script lang="ts">
import router from '@/router';
import FantasyModule from '@/store/modules/FantasyModule';
import LettersModule from '@/store/modules/LettersModule';
import RiddleModule from '@/store/modules/RiddleModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import SyllableModule from '@/store/modules/SyllableModule';
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Client, Form } from '../../../api/types';
import { precacheFiles } from '@/utils/common';
import SchoolModule from '@/store/modules/SchoolModule';

@Component({ components: {} })
export default class WelcomeContainer extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  lettersModule: LettersModule = getModule(LettersModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  isLoading = false;
  hasImage = false;

  get leo(): string {
    return require('@/assets/images/lion.png');
  }

  get isParentClient(): boolean {
    return this.schoolModule.clientType === Client.parent;
  }

  setHasImage(): void {
    this.hasImage = true;
  }

  audios = [
    '/audio/intro.mp3',
    '/audio/show.mp3',
    '/audio/super.mp3',
    '/audio/toll.mp3',
    '/audio/ausgezeichnet.mp3',
    '/audio/grossartig.mp3',
    '/audio/gratuliere_alles.mp3',
  ];
  loadingAudios = false;

  async mounted() {
    // INFO PRELOADING AUDIOS
    try {
      this.loadingAudios = true;
      await precacheFiles(this.audios);
    } catch {
      console.log('err: preloading audios');
    } finally {
      this.loadingAudios = false;
    }
  }

  chooseCorrectFormForParentClient(): Form {
    const enabledA = this.userModule.enabledA.toString() === 'true';
    const enabledB = this.userModule.enabledB.toString() === 'true';
    const doneA = this.userModule.doneA.toString() === 'true';
    const doneB = this.userModule.doneB.toString() === 'true';

    if (enabledA && enabledB) {
      if (!doneA) {
        return Form.a;
      } else if (!doneB) {
        return Form.b;
      } else {
        return Form.b;
      }
    } else if (enabledA) {
      return Form.a;
    } else if (enabledB) {
      return Form.b;
    } else {
      return Form.a;
    }
  }

  async chooseForm(): Promise<void> {
    const form = this.isParentClient
      ? this.chooseCorrectFormForParentClient()
      : this.userModule.currentUser.doneA.toString() === 'true'
      ? Form.b
      : Form.a;

    this.isLoading = true;
    const audio = new Audio('/audio/intro.mp3');
    await Promise.all([
      this.lettersModule.getTestStatus({
        id: this.userModule.currentUser!._id,
        form: form,
      }),
      this.fantasyModule.getTestStatus({
        id: this.userModule.currentUser!._id,
        form: form,
      }),
      this.wordsModule.getTestStatus({
        id: this.userModule.currentUser!._id,
        form: form,
      }),
      this.syllableModule.getTestStatus({
        id: this.userModule.currentUser!._id,
        form: form,
      }),
      this.riddleModule.getTestStatus({
        id: this.userModule.currentUser!._id,
        form: form,
      }),
    ])
      .then(() => {
        //INFO  play intro audio
        this.userModule.setActiveAudio(audio);
        audio.play();
        router.push({
          name: `tests`,
          params: { form: form, schoolName: getSchoolPrefix() },
        });
      })
      .catch(() => {
        this.snackbarModule.showMessage({
          message: 'Fehler!: Bitte versuche es noch einmal!',
          isSuccess: false,
        });
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      });
  }
}
</script>

<style scoped lang="scss">
.welcome {
  &__container {
    height: 100%;
  }
}

.start-btn {
  width: 60%;
  height: 55px !important;

  p {
    color: $dark-blue !important;
    font-family: $font-family, sans serif !important;
  }
}

@media screen and (min-width: 600px) {
  .start-btn {
    width: 40%;
    height: 48px !important;
  }
}

@media screen and (min-width: 960px) {
  .start-btn {
    width: 30%;
    height: 48px !important;
  }
}
@media screen and (min-width: 1264px) {
  .start-btn {
    width: 33%;
    height: 60px !important;
  }
}
</style>
