<template>
  <v-row class="word-container justify-center align-center ma-0 pa-0">
    <div
      class="d-flex justify-center align-center"
      v-for="(letter, idx) in word"
      :key="idx"
    >
      <p
        class="text-h5 text-sm-h4 text-md-h2 text-lg-h1 text-xl-h1 white--text font-weight-medium text-center mb-0 unselectable norddruck"
      >
        {{ letter }}
      </p>
      <SyllablesTestLine
        v-if="idx != word.length - 1"
        :class="{ chosen: isChosen(idx) }"
        @click.native="toggleChosen(idx)"
        :disabled="disableButtons"
        :noHover="noHover"
        :isTutorial="isTutorial"
        :idx="idx"
        :show="showIcon"
      />
    </div>
  </v-row>
</template>

<script lang="ts">
import SyllablesTestLine from './SyllablesTestLine.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { clickSound } from '@/utils/common';
import SyllableModule from '@/store/modules/SyllableModule';
import { getModule } from 'vuex-module-decorators';

@Component({ components: { SyllablesTestLine } })
export default class SyllabesTestAnswerOption extends Vue {
  @Prop() word!: string;
  @Prop() tutorialAnswers?: number[];
  @Prop() disableButtons?: boolean;
  @Prop() isTutorial?: boolean;
  @Prop({ default: false }) noHover?: boolean;
  @Prop({ default: false }) showIcon?: boolean;

  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);

  get answers(): number[] {
    return this.tutorialAnswers ?? this.syllableModule.currentAnswers;
  }

  toggleChosen(idx: number): void {
    clickSound.play();
    if (this.answers.includes(idx)) {
      const index = this.answers.indexOf(idx);
      this.answers.splice(index, 1);
    } else {
      this.answers.push(idx);
    }
    this.answers.sort();
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  isChosen(idx: number): boolean {
    return this.answers.includes(idx);
  }
}
</script>

<style scoped lang="scss">
.word-container {
  height: 15%;
  z-index: 10;

  div {
    height: 50px;
  }
}
</style>
