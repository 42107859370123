<template>
  <v-col cols="12" class="pa-0 ma-0">
    <TutorialEndEmoji
      v-if="showInBetween && !isEnd"
      :text="' Jetzt darfst du alleine üben.'"
      :isTutorial="true"
    />
    <v-col
      cols="12"
      v-else-if="!isEnd"
      class="riddle__answer d-flex flex-column justify-start align-center"
    >
      <StartTestButton
        v-if="!started"
        @start="
          started = true;
          playIntro();
        "
      />

      <template v-if="started">
        <v-col
          cols="12"
          class="riddle__options d-flex flex-column justify-end align-center ma-0 pa-4 pb-0"
        >
          <RepeatButton
            v-if="started"
            :isDisabled="disableArrow || !playSuper"
            @click.native="repeatQuestion"
          />

          <SyllablesTestAnswerOption
            :word="currentQuestion.word"
            class="ma-0 mt-8"
            :tutorialAnswers="tutorialAnswers"
            :disableButtons="disableButtons"
            :isTutorial="true"
            :noHover="noHover"
            :showIcon="showFingerIcon"
          />
          <v-row
            class="btn-row justify-self-end justify-center align-center flex-grow-2 ma-0 mt-16"
          >
            <ArrowButton
              @click.native="answerQuestion"
              :hideButton="hideButton"
              :disabled="disableArrow"
              :class="{ disableMultipleClick: disableArrow }"
            />
          </v-row>
        </v-col>
      </template>
    </v-col>
    <audio
      :src="audioSrc"
      ref="currentAudio"
      @ended="() => playSound()"
      @canplay="onCanPlay"
      @canplaythrough="onCanPlayThrough"
    ></audio>
  </v-col>
</template>

<script lang="ts">
import SyllableModule from '@/store/modules/SyllableModule';
import UserModule from '@/store/modules/UserModule';
import { SyllablesQuestionType } from '@/types';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Ref } from 'vue-property-decorator';
import ArrowButton from '../../base/ArrowButton.vue';
import RepeatButton from '../../base/RepeatButton.vue';
import StartTestButton from '../../base/StartTestButton.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import TutorialMixin from '../TutorialMixin';
import SyllablesTestAnswerOption from './SyllablesTestAnswerOption.vue';

@Component({
  components: {
    SyllablesTestAnswerOption,
    ArrowButton,
    RepeatButton,
    StartTestButton,
    TutorialEndEmoji,
  },
})
export default class SyllablesTestContainer extends TutorialMixin {
  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  idx = 0;
  answered = 0;
  totalQuestions = this.syllableModule.tutorialData.length;
  disableButtons = true;
  hideButton = true;
  tutorialAnswers: number[] | null = [];
  showInBetween = false;
  disableArrow = true;
  playSuper = true;
  showIcon = false;
  started = false;
  tutorialEndSound?: HTMLAudioElement;
  @Ref() currentAudio!: HTMLAudioElement;
  currentAudios: string[] = [];
  audioSrc = '/audio/toll.mp3';
  audioIdx = 0;
  onSoundPlayed: (audioSrc: string) => void = () => null;
  noHover = true;
  canPlayTimeout = 0;

  get audios() {
    return {
      intro: '/audio/tutorials/u3/HELP232.mp3',
      first: '/audio/tutorials/u3/HELP231.mp3',
      second: '/audio/tutorials/u3/HELP233.mp3',
      last: '/audio/tutorials/u3/HELP280.mp3',
      hereWrong: '/audio/tutorials/u3/HELP277.mp3',
      nowPractice: '/audio/tutorials/u3/HELP237.mp3',
      nowClickInBetween: '/audio/tutorials/u3/HELP238.mp3',
      wrong: '/audio/tutorials/u3/HELP239.mp3',
      showYouAgain: '/audio/tutorials/u3/HELP240.mp3',
      super: '/audio/tutorials/u3/HELP3833.mp3',
      wellDone: '/audio/tutorials/u3/HELP289.mp3',
      ready: '/audio/tutorials/u3/HELP3841.mp3',
      readyGo: '/audio/tutorials/u3/HELP3836.mp3',
      click: '/audio/click.mp3',
      default: '/audio/toll.mp3',
    };
  }

  get currentQuestion(): SyllablesQuestionType {
    const maxIdx = this.syllableModule.tutorialQuestions.length - 1;
    this.idx > maxIdx && (this.idx = maxIdx);
    return this.syllableModule.tutorialQuestions[this.idx];
  }

  get isEnd(): boolean {
    return this.answered === this.totalQuestions;
  }

  get correctAnswers(): number[] {
    return this.currentQuestion.solution;
  }

  get showFingerIcon(): boolean {
    return this.showIcon;
  }

  playNextQuestion(): void {
    if (this.idx === 0) {
      this.playIntro();
    } else if (!this.isEnd && this.idx == 1) {
      this.playWrongExample();
    } else if (!this.isEnd && this.idx >= 2) {
      this.playRealExample();
    }
  }

  repeatQuestion(): void {
    this.disableArrow = true;
    this.disableButtons = true;
    this.noHover = true;
    this.userModule.stopActiveAudio();
    this.idx = 0;
    this.answered = 0;
    this.hideButton = true;
    this.syllableModule.resetAnswers();
    this.playNextQuestion();
  }

  playWrongExample(): void {
    const { hereWrong, click, nowPractice } = this.audios;
    this.playSound([hereWrong, click, nowPractice], (audioSrc) => {
      switch (audioSrc) {
        case this.audios.default:
          this.currentAudio.addEventListener(
            'play',
            () => setTimeout(() => (this.showIcon = true), 8000),
            { once: true },
          );
          break;
        case hereWrong:
          this.tutorialAnswers = [];
          break;
        case click:
          this.showInBetween = !this.showInBetween;
          this.showIcon = false;
          break;
        case nowPractice:
          setTimeout(() => {
            this.showInBetween = !this.showInBetween;
            this.idx++;
            this.answered++;
            this.tutorialAnswers = null;
            this.playNextQuestion();
          }, 2000);
          break;
      }
    });
  }

  playRealExample(): void {
    this.hideButton = false;
    this.disableButtons = false;
    this.noHover = false;

    const { nowClickInBetween } = this.audios;
    this.playSound([nowClickInBetween], (audioSrc) => {
      audioSrc === nowClickInBetween && (this.disableArrow = false);
    });
  }

  created(): void {
    this.syllableModule.resetAnswers();
  }

  playIntro(): void {
    this.tutorialEndSound = new Audio(this.audios.readyGo);
    const { intro, first, second, last, click } = this.audios;
    this.playSound([intro, first, click, second, click, last], (audioSrc) => {
      audioSrc === first &&
        (this.tutorialAnswers = this.correctAnswers.slice(0, 1));
      audioSrc === second && (this.tutorialAnswers = this.correctAnswers);
      audioSrc === last &&
        setTimeout(() => {
          this.idx++;
          this.answered++;
          this.tutorialAnswers = [2];
          this.playNextQuestion();
        }, 1000);
    });
  }

  playCorrectAnswer(): void {
    const afterSuper = () => {
      this.idx++;
      this.answered++;
      if (this.isTutorialOnlyVersion) {
        this.redirectOnTutorialOnly();
      } else {
        this.syllableModule.finishedTutorial();
        this.syllableModule.resetAnswers();
        this.userModule.setActiveAudio(this.tutorialEndSound!);
        this.tutorialEndSound!.play();
      }
    };

    if (this.playSuper) {
      this.playSound([this.audios.super], (audioSrc) => {
        audioSrc === this.audios.super && afterSuper();
      });
    } else {
      afterSuper();
    }
  }

  playWrongAnswer(): void {
    this.tutorialAnswers = [];
    this.disableButtons = true;
    this.noHover = true;
    const { wrong, showYouAgain, click } = this.audios;
    this.playSound([wrong, showYouAgain, click, click], (audioSrc) => {
      // highlight correct letter consecutively & set correct answers
      switch (audioSrc) {
        case showYouAgain:
          setTimeout(() => {
            this.idx++;
            this.answered++;
            if (this.isTutorialOnlyVersion) {
              this.redirectOnTutorialOnly();
            } else {
              this.syllableModule.resetAnswers();
              this.userModule.setActiveAudio(this.tutorialEndSound!);
              this.tutorialEndSound!.play();
              this.syllableModule.finishedTutorial();
            }
          }, 6000);
          break;
        case click:
          this.tutorialAnswers || (this.tutorialAnswers = []);
          this.tutorialAnswers.push(
            this.currentQuestion.solution[this.tutorialAnswers.length],
          );
          break;
      }

      this.syllableModule.setToCorrectAnswer(this.currentQuestion.solution);
    });
  }

  onCanPlay() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
    this.canPlayTimeout = window.setTimeout(
      this.onCanPlayThrough.bind(this),
      6 * 1000,
    );
  }

  onCanPlayThrough() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
    this.canPlayTimeout && (this.canPlayTimeout = 0);
    if (this.audioSrc !== this.audios.default && this.currentAudio.paused) {
      this.userModule.setActiveAudio(this.currentAudio);
      this.currentAudio.play();
    }
  }

  playSound(
    sounds: string[] | undefined = undefined,
    onSoundPlayed: (audioSrc: string) => void = this.onSoundPlayed,
  ) {
    sounds && (this.currentAudios = sounds);
    sounds && (this.audioSrc = this.audios.default);
    onSoundPlayed !== this.onSoundPlayed &&
      (this.onSoundPlayed = onSoundPlayed);

    this.audioIdx = this.currentAudios.indexOf(
      this.audioSrc,
      this.audioIdx + 1,
    );
    const nextAudioIdx = this.audioIdx + 1;

    onSoundPlayed?.(this.audioIdx >= 0 ? this.audioSrc : this.audios.default);

    if (nextAudioIdx < this.currentAudios.length) {
      if (this.audioSrc === this.currentAudios[nextAudioIdx]) {
        this.currentAudio.play();
      } else {
        this.audioSrc = this.currentAudios[nextAudioIdx];
      }
      this.audioSrc = this.currentAudios[nextAudioIdx];
    }
  }

  answerQuestion(): void {
    //CHECK IF CORRECTLY ANSWERED
    this.disableArrow = true;
    const isCorrect = this.checkAnswer();
    if (isCorrect) {
      this.playCorrectAnswer();
    } else {
      this.playWrongAnswer();
    }
  }

  checkAnswer(): boolean {
    const solution = this.currentQuestion.solution;
    const answers = this.syllableModule.currentAnswers;
    return (
      solution.length === answers.length &&
      solution.every((answer, idx) => answer === answers[idx])
    );
  }

  beforeDestroy() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
  }
}
</script>

<style scoped lang="scss">
.riddle {
  &__answer {
    width: 100%;
    height: 60vh;
  }

  &__options {
    height: 50%;
    margin-left: 10.5%;
  }
}

.btn-row {
  width: 20%;
}

.chosen {
  border: 5px orange solid !important;
}

.hide {
  display: none;
}

.top-margin {
  margin-top: 8%;
}
</style>
