<template>
  <v-btn
    v-if="isTutorial"
    class="line ma-0 px-1 TESTLINE"
    :class="{ noHover: noHover }"
  >
    <v-col
      class="tutorial d-flex flex-column justify-center align-center"
      cols="12"
    >
      <div class="balken"></div>
      <v-img
        v-if="showCursor"
        class="tutorial__icon"
        :class="{ hide: !showCursor }"
        contain
        eager
        :src="cursor"
        max-height="32"
        max-width="35"
      />
    </v-col>
  </v-btn>

  <v-btn v-else class="line ma-0 px-1"
    ><div class="balken" cols="12"></div
  ></v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component
export default class SyllableTestLine extends Vue {
  @Prop() isTutorial!: boolean;
  @Prop({ default: false }) show?: boolean;
  @Prop() idx?: number;
  @Prop() noHover?: boolean;

  showIcon = true;

  get cursor(): string {
    return require('@/assets/images/finger.png');
  }

  get showCursor(): boolean {
    const idxOfWrongExample = 2;
    const show = this.show! && this.idx === idxOfWrongExample;
    return show && this.showIcon;
  }

  turnIconOff(): void {
    if (this.show) {
      setTimeout(async () => {
        this.showIcon = false;
      }, 2000);
    }
  }

  updated(): void {
    this.turnIconOff();
  }
}
</script>

<style scoped lang="scss">
.line {
  background-color: transparent !important;
  border: 2px solid transparent !important;
  box-shadow: none !important;
  height: 60px !important;
  width: 15px !important;
  min-width: 6px !important;
  border-radius: 0 !important;
  position: relative;
}

.line:disabled {
  pointer-events: none !important;
  touch-action: none !important;
}

@media (hover: hover) and (pointer: fine) {
  .line:hover {
    border: 3px solid transparent !important;
  }

  .line.noHover:hover {
    pointer-events: none !important;
    border: 3px solid transparent !important;
  }
}

.balken {
  height: 60px !important;
  width: 8px;
}

@media only screen and (min-width: 1200px) {
  .line {
    height: 100px !important;
  }
  .balken {
    height: 100px !important;
    width: 10px;
  }
}

.tutorial {
  position: relative;

  &__icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    margin-right: -5px;
  }
}

.chosen.tutorial {
  .line .balken {
    background-color: orange !important;
  }
}
.chosen.line .balken {
  background-color: orange !important;
}

.hide {
  display: none;
}
</style>
